import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { confirmPasswordReset } from "firebase/auth";
import { auth } from '../../firebase/config';
import {
    Container, Box, TextField, Button, Typography, Grid, Divider, IconButton, CircularProgress, Link as MuiLink
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ResetPasswordPage: React.FC = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        if (newPassword !== confirmPassword) {
            setPasswordError("Passwords do not match.");
            setLoading(false);
            return;
        }

        const params = new URLSearchParams(window.location.search);
        const oobCode = params.get('oobCode');

        if (oobCode) {
            try {
                await confirmPasswordReset(auth, oobCode, newPassword);
                navigate('/auth/login'); // Navigate to login page on success
            } catch (error: any) {
                console.error("Error resetting password", error);
                setError("Failed to reset password. The link may be expired or invalid.");
            }
        } else {
            setError("Invalid request. No code found in URL.");
        }

        setLoading(false);
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box sx={{ mt: 10, pt: 4, pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Typography component="h1" variant="h1" sx={{ mt: 3, mb: 1, maxWidth: "100%" }}>
                    Reset Password
                </Typography>
                <Typography component="h1" variant="body1" sx={{ mb: 4 }}>
                    Choose a new password for your account.
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="New Password"
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!passwordError}
                        helperText={passwordError}
                    />
                    {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={loading}
                        sx={{ mt: 3, mb: 2, maxWidth: '75%' }}
                    >
                        Reset Password
                    </LoadingButton>
                </Box>
                <MuiLink to="/auth/login" color="inherit" component={Link}>
                    <Typography variant="body2" sx={{ mt: 4 }}>
                        Remembered your password? Sign in
                    </Typography>
                </MuiLink>
            </Box>
        </Container>
    );
};

export default ResetPasswordPage;

export const Head: React.FC = () => <title>ScreenKeep - Reset Password</title>;